$(document).ready(function () {
  $(document).on('click', '.feedback .close-btn', function () {
    $('#profile-dialog').dialog('close');
    $('#profile-dialog').html('');
  });
  $(document).on('click', '.feedback .submit-form', function () {
    event.preventDefault();
    Sceon.Feedback.Send();
  });

  $(document).on('click', '#profile-dialog .reset-btn', function () {
    $('#profile-dialog input:not(.not-reset)').val('');
    $('#profile-dialog textarea').val('');
    $('#profile-dialog .input-wrapper').removeClass('invalid');
    $('#profile-dialog .error').html('');
  });

  Sceon.Feedback.Show = function (element, escort_id, direct, params) {
    event.preventDefault();
    let $element = $(element).closest('span');

    if (!$element.length) {
      $element = $(element).closest('.overlay-parent');
    }

    let f_overlay = new Sceon.Overlay($element, {
      loader: '/img/loader-tiny.gif',
      offset: { bottom: 0, top: 0 },
    });

    f_overlay.enable();

    let url =
      Sceon.Feedback.url +
      '?to=support&about=' +
      (escort_id ? escort_id : 'support') +
      '&ajax';
    if (direct) {
      url = `${Sceon.Feedback.url}?to=${escort_id}&showname=${params.showname}`;
      if (params.email_id) {
        url = `${url}&email_id=${params.email_id}`;
      }
      if (params.type) {
        url = `${url}&type=${params.type}`;
      }
    }
    grecaptcha.ready(function () {
      $.ajax({
        url: url,
        method: 'GET',
        success: async function (resp) {
          let token;
          try {
            token = await grecaptcha.execute(envConfigs.reCAPTCHA_site_key, {
              action: 'write_email',
            });
          } catch (e) {
            token = 'some shit token';
          }
          if (!$('#profile-dialog').length) {
            $('main').append($("<div id='profile-dialog'></div>"));
          }
          $('#profile-dialog').html(resp);
          $('#profile-dialog .show-dialog').trigger('click');

          $('#captcha').val(token);
          f_overlay.disable();
        },
      });
    });
  };

  Sceon.Feedback.Send = function () {
    let fp_overlay = new Sceon.Overlay($('.popup-form'), {
      loader: '/img/loader-tiny.gif',
      offset: { bottom: 0, top: 0 },
    });
    fp_overlay.enable();

    let $form = $('#profile-dialog').find('form');
    let url = $form.attr('action');
    $.ajax({
      url,
      method: 'POST',
      data: $form.serialize(),
      success: async function (resp) {
        if (resp.status == 'success') {
          $('.modal-title').text('');
          $('.modal-body').html(resp.msg);
          $('.modal-footer').remove();
        } else if (resp.status == 'error') {
          $('.error').html('');
          for (let msg in resp.msgs) {
            if ($(`.error#${msg}`).length) {
              $(`.error#${msg}`).append(resp.msgs[msg]);
            } else if ($(`.error#comment`).length) {
              $(`.error#comment`).append(resp.msgs[msg]);
            } else {
              $(`.error#message`).append(resp.msgs[msg]);
            }
          }
          let token;
          try {
            token = await grecaptcha.execute(envConfigs.reCAPTCHA_site_key, {
              action: 'write_email',
            });
          } catch (e) {
            token = 'some shit';
          }
          $('#captcha').val(token);
        }
        fp_overlay.disable();
      },
    });
  };
});
